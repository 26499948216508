import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Schedule', url: '/home', icon: 'alert-circle' },
    // { title: 'About', url: '/about', icon: 'information-circle' },
  ];
  public subPages = [
    { title: 'About', url: '/about', icon: 'help-circle' },
    { title: 'Privacy', url: '/privacy', icon: 'person-circle' },
    // { title: 'Terms', url: '/terms', icon: 'information-circle' },
  ];
  constructor() {}
}
